@import '../../abstracts/_mixins';
@import '../../abstracts/_variables';
@import '../../components/title';
@import '../../components/button';

.add-cart {
    &.offcanvas-collapse-product {
        position: fixed;
        visibility: hidden;
        top: 1rem;
        bottom: 1rem;
        left: 100%;
        width: 40vw;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: var(--toc-white);
        border-radius: 0.5rem 0 0 0.5rem;
        transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
        z-index: 4;

        &.open {
            visibility: visible;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }


    &__modal {
        &__product {
            &__img {
                &__wrapper {
                    &__picture {
                        display: block;
                        position: relative;
                        height: auto;
                        width: 18rem;
                        height: 18rem;
                        margin: auto;
                        background-position: center;
                        background-size: cover;
                        border-radius: 50%;
                        background-color: #F0F0F3;
                        box-shadow: 0 6px 6px #0000001f;
                    }
                }
            }

            &__info {
                &__name {
                    font-size: 3rem;
                    font-weight: bold;
                    color: var(--toc-brown);
                    line-height: 1;
                }

                &__added-to-cart {
                    font-size: 1.5rem;
                    line-height: 3;
                    color: var(--toc-green);
                    background-color: var(--toc-grey);

                    &::before {
                        @include icons($fontAwesomeFree, $check, 2rem, var(--toc-green));
                    }

                }
            }
        }

        &__crossseling {
            @include productCard();

            &__title {
                h2 {
                    margin-bottom: 2rem;

                    span {
                        @include subTitle();
                        border-bottom: 3px solid var(--toc-orange);
                    }
                }
            }

            button.icon-shopping {
                border: none;
                background: none;
            }

            .swiper-button-prev {
                &:after {
                    color: var(--toc-orange)
                }
            }

            .swiper-button-next {
                &:after {
                    color: var(--toc-orange)
                }
            }
        }

        &__footer {
            @include flex-row-justify(space-between);
            row-gap: 1rem;

            &__continue {
                &--button {
                    @include button-cta(secondary);
                    @include flex-row-justify(center);
                    width: 100%;
                    gap: 1rem;
                    max-height: 4rem;
                    color: var(--toc-white) !important; //override mixin button

                    &::before {
                        //override mixin icons
                        content: '\f060' !important; //override mixin icon
                        font-size: 2rem !important; //override mixin icon
                    }
                }
            }

            &__order {
                &--button {
                    @include button-cta(primary);
                    @include flex-row-justify(center);
                    gap: 1rem;
                    width: 100%;
                    max-height: 4rem;

                    &::before {
                        //override mixin icons
                        font-size: 2rem !important;
                    }

                    &:hover {
                        color: var(--toc-white) !important;
                    }
                }
            }
        }
    }

    @media (max-width: $breakpoint-xl) {
        &.displayed {
            inset: 1rem;
        }

        &.offcanvas-collapse-product {
            width: 70vw;
        }
    }

    @media (max-width: $breakpoint-lg) {
        &.displayed {
            inset: 1rem;
        }

        &.offcanvas-collapse-product {
            width: 95vw;
            height: 98vh;
        }

        &__modal {
            overflow: auto;
        }
    }
}

.layer_cart_overlay {
    display: none;

    &.displayed {
        display: inline-block;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
        position: fixed;
        inset: 0;
        background: #35180c9e;
        z-index: 3;
    }
}

.shopping_cart {
    flex: 1 1;
    font-weight: 600;
    text-align: center;
    position: relative;

    .toc-icon {
        display: block;
        margin: 0px auto 5px auto;
        font-size: 36px;
        color: #333;
    }

    a {
        color: #333;
        font-size: 14px;
    }
}

.shopping_cart_qties {
    position: absolute;
    top: 0px;
    width: 2rem;
    height: 2rem;
    padding-top: 1.5px;
    border-radius: 50%;
    color: var(--toc-white);
    background: var(--toc-orange);
}